<template>
  <div class="tableAdminTeacher">
    <div class="cui__utils__heading mb-0">
      <strong>Rules</strong>
    </div>
    <div
      class="text-muted mb-3"
    >On this page you can view, search, add, edit, change password, and delete teachers</div>
    <hr />
    <div :class="[!isMobile ? 'd-flex' : '', !isMobile ? 'flex-nowrap' : '']">
      <div>
        <a-form-item>
          <a-input
            v-model="search"
            @keyup="searchTeacher"
            placeholder="Search by NIK or Nama.."
            :style="{
              width: isMobile ? '100%' : '225px',
              height: '40px'
            }"
          ></a-input>
        </a-form-item>
      </div>
      <div :class="['ml-auto', isMobile ? 'mb-4' : '']">
        <a-button :block="isMobile" @click="toAddTeacher" type="primary" icon="plus" style="height:40px">Add Teacher</a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="Nik" slot-scope="text">{{text ? text : '-'}}</span>
      <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toDetailTeacher(record.key)"
          :class="['btn', 'btn-outline-success', 'mx-2', isMobile ? 'd-block' : '', isMobile ? 'mb-2' : '']">
          Detail
        </a>
        <a
          @click.prevent="toScheduleTeacher(record.key)"
          class="btn btn-outline-primary mx-2"
        >Schedule</a>
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'NIK',
    dataIndex: 'Nik',
    // width: '15%',
    scopedSlots: {
      customRender: 'Nik',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Nama',
    dataIndex: 'Nama',
    sorter: true,
    // width: '32%',
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: '20%',
    scopedSlots: { customRender: 'Actions' },
  },
]
export default {
  data() {
    return {
      search: '',
      columns,
      dataTable: [],
      pagination: {},
      loading: false,
    }
  },
  methods: {
    toAddTeacher() {
      this.$router.push({ name: 'Add Teacher Admin' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    toDetailTeacher(id) {
      this.$router.push({ name: 'Detail Teacher Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    toScheduleTeacher(id) {
      this.$router.push({ name: 'Schedule Teacher Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    searchTeacher() {
      if (this.search.length > 2) {
        this.fetchData({
          search: this.search,
        })
      } else {
        this.fetchData()
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchData({
        page: pagination.current,
        order: order,
      })
    },
    fetchData(params = { page: 1, order: 'ASC', search: '' }) {
      this.loading = true
      this.$store.dispatch('admin/FETCH_TEACHERS', { page: params.page, order: params.order, search: params.search })
        .then(data => {
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loading = false
          this.dataTable = data.guru.map(row => {
            return {
              key: row.id,
              Nik: row.NIK,
              Nama: row.nama,
            }
          })
          this.pagination = pagination
        })
    },
  },
  created() {
    this.fetchData()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang = 'scss'>
.tableAdminTeacher {
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #f56a79 !important;
    color: white !important;
    color: #f56a79 !important;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }

  .btn.btn-outline-danger:hover,
  .btn.btn-outline-danger:active,
  .show > .btn.btn-outline-danger:hover,
  .show > .btn.btn-outline-danger:active {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }
}
</style>
